import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/utils/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found - Marek Maras • Frontend Webentwicklung" />

    <section className="flex flex-col justify-center w-auto h-full">
      <div className="flex flex-col align-center content-center self-center">
      <h1 className="text-center text-4xl font-thin mb-8">SEITE NICHT GEFUNDEN :(</h1>
      <p>Da ging was schief... Diese Enttäuschung... diese Traurigkeit!.</p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
